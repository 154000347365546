import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from './../layout/Spinner';
import DealsActions from './DealsActions';
import { getSponsorships } from './../../actions/sponsorship';
import { getDealForClients, updateDeal, publishDeals } from './../../actions/deal';
import { addAdvertMessage } from './../../actions/chat';
import pdfIcon from '../../img/pdf.png';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from './../layout/Pagination';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const Deals = ({ getDealForClients, getSponsorships, updateDeal, publishDeals, addAdvertMessage, auth: { user, theme }, deal: { users_deals }, sponsorship: { loading } }) => {

  const location = useLocation();
    let history = useHistory();

    const [modalsSecondOpen, setModalSecondIsOpen] = useState(false);
    const [modalsThirdOpen, setModalThirdIsOpen] = useState(false);
    const [modalsFourthOpen, setModalFourthIsOpen] = useState(false);
    const [modalsSendMessageOpen, setModalSendMessageIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFirstButton, setModalFirstButton] = useState("");
    const [modalSecondButton, setModalSecondButton] = useState("");
    const [dealId, setDealId] = useState("");
    const [myDeal, setMyDeal] = useState("");
    const [modalsDetailOpen, setModalDetailIsOpen] = useState(false);
    const [modalsPublishApprovedDealsOpen, setModalPublishApprovedDealsIsOpen] = useState(false);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [dealsPerPage] = useState(10);
  
    const [criteria, setCriteria] = useState("All");
    const [searchCriteria, setSearchCriteria] = useState("Presenter");
    const [searchField, setSearchField] = useState("");  
  
    const [myDealsChange, setMyDealsChange] = useState(false);

    const [dealImages, setDealImages] = useState([]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isZooming, setIsZooming] = useState(false);
    const [zoomStyle, setZoomStyle] = useState({});

    const [myMessage, setMyMessage] = useState("");

    const [country, setCountry] = useState("");
    const [countryLabel, setCountryLabel] = useState("");
  

  useEffect(() => {
    getDealForClients(user._id);
  }, [getDealForClients, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);



  const { t } = useTranslation();


  var numberCounter = 0;

  const indexOfLastDeal = currentPage * dealsPerPage;
  const indexOfFirstDeal = indexOfLastDeal - dealsPerPage;
  var currentDeals = [];
  var sortedDeals = [];
  if (users_deals && users_deals.length > 0) {
    let tempData = [...users_deals];
    for (var i = 0; i < tempData.length; i++) {

      if (i > 0 && (tempData[i - 1].date_creation !== tempData[i].date_creation)) {
        numberCounter = 0;
      }
      else {
        if (i > 0 && ((criteria === "All") || (criteria === "Pending" && tempData[i - 1].state === 1)
          || (criteria === "Approved" && tempData[i - 1].state === 2)
          || (criteria === "Online" && tempData[i - 1].state === 3)
          || (criteria === "Suspended" && tempData[i - 1].state === 4)
          || (criteria === "Deleted" && tempData[i - 1].state === 5)
          )) {
            numberCounter ++;
        }
      }
      tempData[i].number = numberCounter + 1;

      if (!myDealsChange || (myDealsChange === true && tempData[i].user === user._id)) {
        if (country === "" || tempData[i].country === country) {
        if ((criteria === "All") || (criteria === "Pending" && tempData[i].status === 1)
          || (criteria === "Approved" && tempData[i].status === 2)
          || (criteria === "Online" && tempData[i].status === 3)
          || (criteria === "Suspended" && tempData[i].status === 4)
          || (criteria === "Deleted" && tempData[i].status === 5))
      {
      if (searchField) {
           if (searchCriteria === "Presenter") {
        if (tempData[i].presenter.toUpperCase().startsWith(searchField.toUpperCase())) {
            sortedDeals.push(tempData[i]);
        }
      }
        else if (searchCriteria === "Title") {
          if (tempData[i].title.toUpperCase() === "") {
            if (t('Language.Lang') === "Français") {
              if ("Aucun".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedDeals.push(tempData[i]);
            }
            }
            else if (t('Language.Lang') === "Italiano") {
              if ("Nessuna".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedDeals.push(tempData[i]);
            }
            }
            else {
              if ("None".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedDeals.push(tempData[i]);
            }
            }
          }
          else {
            if (tempData[i].title.toUpperCase().startsWith(searchField.toUpperCase())) {
              sortedDeals.push(tempData[i]);
          }
          }
        }
        else if (searchCriteria === "City") {
          if (tempData[i].city.toUpperCase().startsWith(searchField.toUpperCase())) {
            sortedDeals.push(tempData[i]);
          }
        }

        else if (searchCriteria === "Postal_code") {
          if (tempData[i].postal_code.toUpperCase().startsWith(searchField.toUpperCase())) {
            sortedDeals.push(tempData[i]);
          }
        }
      else if (searchCriteria === "D") {
        var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
        var finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }

      if (finalDateDay.startsWith(searchField)) {
        sortedDeals.push(tempData[i]);
      }
    }
    else if (searchCriteria === "M") {
      var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
      
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
     

    if (dateMonth.startsWith(searchField)) {
        sortedDeals.push(tempData[i]);
    }
  }
  else if (searchCriteria === "Y") {

    var dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]


  if (dateYear.startsWith(searchField)) {
    sortedDeals.push(tempData[i]);
  }
}
      }
      else {
        sortedDeals.push(tempData[i]);
      }
    }
    }
    }
  }

    currentDeals = sortedDeals.reverse().slice(indexOfFirstDeal, indexOfLastDeal);
  }


  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedDeals.length / dealsPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(sortedDeals.length / dealsPerPage));



  function handleCriteriaChange (changeEvent) {
    if(changeEvent.currentTarget.value === "All") {
      setMyDealsChange(false)
  }
  else {
      setMyDealsChange(true)
  }
    setCriteria(changeEvent.currentTarget.value);
    setCurrentPage(1);
}

function handleSearchCriteriaChange (changeEvent) {

  if ((changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
    setSearchField("");
  }
  else if ((changeEvent.currentTarget.value === "Presenter" || changeEvent.currentTarget.value === "Title" || changeEvent.currentTarget.value === "City" || changeEvent.currentTarget.value === "Postal_code") && !isNaN(searchField)) {
    setSearchField("");
  }

  setSearchCriteria(changeEvent.currentTarget.value);

  setCurrentPage(1);
}

const handleNext = () => {
  setCurrentIndex((prevIndex) => (prevIndex + 1) % dealImages.length);
};

const handlePrev = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex === 0 ? dealImages.length - 1 : prevIndex - 1
  );
};

const handleZoom = (e) => {
  const rect = e.target.getBoundingClientRect();
  const x = ((e.clientX - rect.left) / rect.width) * 100;
  const y = ((e.clientY - rect.top) / rect.height) * 100;

  setZoomStyle({
    transformOrigin: `${x}% ${y}%`,
    transform: "scale(2)", // Ajustez la valeur du zoom ici
  });
  setIsZooming(true);
};

const resetZoom = () => {
  setIsZooming(false);
  setZoomStyle({});
};


function handleMyDealsChange () {
  setMyDealsChange(!myDealsChange);
  setCurrentPage(1);
}
      


      const showTable =
      
      
      <Fragment>
      <br/><br/>
      
<h1 className='large text-primary'>{t('Deals.Deal_list')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme">

<div className='form'>
<div className="form-group">
          <input
            type='text'
            placeholder={t('Deals.Search_by') + " " + (searchCriteria === "Presenter" ? t('Deals.Presenter').toLowerCase() : searchCriteria === "Title" ? t('Deals.Title').toLowerCase() : searchCriteria === "City" ? t('Deals.City_locality').toLowerCase() : searchCriteria === "Postal_code" ? t('Deals.Postal_code').toLowerCase() : searchCriteria === "D" ? t('Deals.Day_text').toLowerCase() : searchCriteria === "M" ? t('Deals.Month_text').toLowerCase() : searchCriteria === "Y" ? t('Deals.Year_text').toLowerCase() : "")}
            name='searchField'
            value={searchField}
            onChange={e => findByCriteria(e.target.value)}
          />
        </div>
</div>


<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Deals.Search_by')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>



      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Presenter" 
         id="presenter"
         className="custom-control-input"
                      checked={searchCriteria === 'Presenter'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="presenter">
          {t('Deals.Presenter')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Title" 
         id="title"
         className="custom-control-input"
                      checked={searchCriteria === 'Title'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="title">
          {t('Deals.Title')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="City" 
           id="city"
           className="custom-control-input"
                        checked={searchCriteria === 'City'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="city">
            {t('Deals.City_locality')}
        </label>
        </div>
  
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Postal_code" 
          id="postal_code"
          className="custom-control-input"
                        checked={searchCriteria === 'Postal_code'} 
                        onChange={handleSearchCriteriaChange} />
                        <label className="custom-control-label" for="postal_code">
            {t('Deals.Postal_code')}
        </label>
        </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="day"
         className="custom-control-input"
                      checked={searchCriteria === 'D'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="day">
          {t('Deals.Day')}
      </label>
      </div>
      
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="month"
         className="custom-control-input"
                      checked={searchCriteria === 'M'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="month">
          {t('Deals.Month')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Y" 
         id="year"
         className="custom-control-input"
                      checked={searchCriteria === 'Y'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="year">
          {t('Deals.Year')}
      </label>
      </div>

    </div>

<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Deals.State')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="All" 
         id="all"
         className="custom-control-input"
                      checked={criteria === 'All'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="all">
          {t('Deals.All')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Pending" 
           id="pending"
           className="custom-control-input"
                        checked={criteria === 'Pending'} 
                        onChange={handleCriteriaChange} />
                        <label className="custom-control-label" for="pending">
            {t('Deals.Pending')}
        </label>
        </div>
  
    
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Approved" 
           id="approved"
           className="custom-control-input"
                        checked={criteria === 'Approved'} 
                        onChange={handleCriteriaChange} />
                        <label className="custom-control-label" for="approved">
            {t('Deals.Approved')}
        </label>
        </div>

        <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Online" 
         id="online"
         className="custom-control-input"
                      checked={criteria === 'Online'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="online">
          {t('Deals.Online')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Suspended" 
         id="suspended"
         className="custom-control-input"
                      checked={criteria === 'Suspended'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="suspended">
          {t('Deals.Suspended')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Deleted" 
         id="deleted"
         className="custom-control-input"
                      checked={criteria === 'Deleted'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="deleted">
          {t('Deals.Deleted')}
      </label>
      </div>

    </div>

    <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Deals.Other_criteria')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="my_deals"
         className="custom-control-input"
                      checked={myDealsChange === true} 
                      onChange={handleMyDealsChange} />
                      <label className="custom-control-label" for="my_deals">
          {t('Deals.My_deals')}
      </label>
      </div>
    </div>

  
    <div className="form-group" style={{
     display: "flex",
     alignItems: "center" }}>
           <h4>
      {t('Deals.Filter')}:
            </h4>
          <label style={{marginRight:"20px", marginTop: "10px"}}>
  
        </label>

      <div className='form-group px-2'>
<select className="form-control" name="action" value={countryLabel} onChange={e => dropDownSelectedCountry(e.target.value)}>
<option value="">{t('Purchases.Select_country')}</option>
            <option value= {'{"index": ""}'}>{t('Deals.All')}</option>
             <option value= {'{"index": "BE"}'}>{t('Country.BE')}</option>
             <option value= {'{"index": "FR"}'}>{t('Country.FR')}</option>
             <option value= {'{"index": "IT"}'}>{t('Country.IT')}</option>
             <option value= {'{"index": "LU"}'}>{t('Country.LU')}</option>
             <option value= {'{"index": "CH"}'}>{t('Country.CH')}</option>
             <option value= {'{"index": "GB"}'}>{t('Country.GB')}</option>
             <option value= {'{"index": "GP"}'}>{t('Country.GP')}</option>
             <option value= {'{"index": "GF"}'}>{t('Country.GF')}</option>
             <option value= {'{"index": "MQ"}'}>{t('Country.MQ')}</option>
             <option value= {'{"index": "RE"}'}>{t('Country.RE')}</option>
           </select>
        </div>
  
        </div>
  
<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('Deals.Number')}</th>
      <th scope="col">{t('Deals.Date')}</th>
      <th scope="col">{t('Deals.Image')}</th>
      <th scope="col">{t('Deals.Presenter')}</th>
      <th scope="col">{t('Deals.Title')}</th>
      <th scope="col">{t('Deals.Amount')}</th>
      <th scope="col">{t('Deals.City_locality')}</th>
      <th scope="col">{t('Deals.Postal_code')}</th>
      <th scope="col">{t('Deals.Country')}</th>
      <th scope="col">{t('Deals.State')}</th>
      <th scope="col">{t('Deals.Actions')}</th>
    </tr>
  </thead>
  <tbody>


  {!users_deals  ? 
          <tr>
          <th colSpan={11}>{t('Deals.Calculating') + " ..."}</th>
        </tr>
      :
      sortedDeals.length === 0 ?
      <tr>
          <th colSpan={11}>{t('Deals.No_deals')}</th>
        </tr>
       :
      currentDeals.map(( get_deal, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))

     return (
    <tr key={index}>
      
      <td>{sortedDeals.length - index}</td>

    <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_deal.date_creation.substring(0, 10).split("-")[2] + '/' + get_deal.date_creation.substring(0, 10).split("-")[1] + '/' + get_deal.date_creation.substring(0, 10).split("-")[0] : get_deal.date_creation.substring(0, 10).split("-")[1] + '/' + get_deal.date_creation.substring(0, 10).split("-")[2] + '/' + get_deal.date_creation.substring(0, 10).split("-")[0]}</td>
    <td><div style={{width: 50, height: 'auto', display: get_deal.attachment_name_first.split('.').pop() === "pdf" ? "block" : "none"}}>
           <a href={get_deal.attachment_name_first} target="_blank"  rel="noopener noreferrer"><img border="0" alt="pdf" src={pdfIcon} /></a></div>
           <a href={get_deal.attachment_name_first} target="_blank"  rel="noopener noreferrer"><img src={get_deal.attachment_name_first} alt="" className="rounded-lg" style = {{ display: get_deal.attachment_name_first.split('.').pop() !== "pdf" ? 'block' : "none",
            maxWidth:'75px',
            maxHeight:'75px',
            width: 'auto',
            height: 'auto'}}/></a></td>
      <td>{get_deal.presenter}</td>
      <td>{get_deal.title}</td>
      <td><s>{get_deal.initial_selling_price.toFixed(2) + "€ "}</s>{get_deal.negotiated_selling_price.toFixed(2) + "€"}</td>
      <td>{get_deal.city}</td>
      <td>{get_deal.postal_code}</td>
      <td>{t(`Country.${get_deal.country}`)}</td>
    <td>
    {(get_deal.status === 1 ? t('Deals.Pending') :
    get_deal.status === 2 ? t('Deals.Approved') :
    get_deal.status === 3 ? t('Deals.Online') :
    get_deal.status === 4 ? t('Deals.Suspended') :
    get_deal.status === 5 ? t('Deals.Deleted') : "" )}</td>
      <td>
      {get_deal.status === 3 && get_deal.user === user._id ? 
               <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Deals.Select_action')}</option>
               <option value= {'{"index": "1", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Details')}</option>
               <option value= {'{"index": "4", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Suspend')}</option>
               <option value= {'{"index": "5", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Delete')}</option>
             </select>
             :
             get_deal.status === 4 && get_deal.user === user._id ? 
               <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Deals.Select_action')}</option>
               <option value= {'{"index": "1", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Details')}</option>
               <option value= {'{"index": "3", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Publish')}</option>
               <option value= {'{"index": "5", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Delete')}</option>
             </select>
             : get_deal.user === user._id && get_deal.status !== 5 ?
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Deals.Select_action')}</option>
               <option value= {'{"index": "1", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Details')}</option>
               <option value= {'{"index": "5", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Delete')}</option>
             </select>
             :
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Deals.Select_action')}</option>
               <option value= {'{"index": "1", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Details')}</option>
               <option value= {'{"index": "6", "deal": {"_id": "' + get_deal._id + '", "new_id": "' + get_deal.new_id + '", "presenter": "' + get_deal.presenter + '", "title": "' + get_deal.title + '", "description": "' + get_deal.description + '", "initial_selling_price": "' + get_deal.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_deal.negotiated_selling_price.toFixed(2) + '", "seller": "' + get_deal.seller + '", "delivery_method": "' + get_deal.delivery_method + '", "further_information": "' + get_deal.further_information + '", "address": "' + get_deal.address + '", "city": "' + get_deal.city + '", "postal_code": "' + get_deal.postal_code + '", "country": "' + get_deal.country + '", "attachment_name_first": "' + get_deal.attachment_name_first + '", "attachment_name_second": "' + get_deal.attachment_name_second + '", "attachment_name_third": "' + get_deal.attachment_name_third + '", "status": "' + get_deal.status + '", "date_creation": "' + get_deal.date_creation +'"}}'}>{t('Deals.Send_message')}</option>
             </select>
             }
      </td>
    </tr>
  );
})}

  </tbody>
</table>
</div>
</div>


<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ sortedDeals.length > 0 ? <Pagination usersPerPage={dealsPerPage} totalUsers={users_deals && sortedDeals.length > 0 ? sortedDeals.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>
  </Fragment>;



const showModalPublish = 
<Modal className="my-modal" show={modalsSecondOpen} onHide={() => setModalSecondIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => publishButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalSecondIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalSuspend = 
<Modal className="my-modal" show={modalsThirdOpen} onHide={() => setModalThirdIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => suspendButtonClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalThirdIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>



const showModalDelete = 
<Modal className="my-modal" show={modalsFourthOpen} onHide={() => setModalFourthIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-danger" onClick={() => deleteButtonClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalFourthIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>



const showModalDetail = 
<Modal className="my-modal" show={modalsDetailOpen} onHide={() => setModalDetailIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>  {dealImages.length > 0 ? (
<div className="carousel-container">
  <div className="carousel-wrapper">
    <div className="carousel-slides">
    <div
                      className="carousel-item-wrapper"
                      onMouseMove={handleZoom}
                      onMouseLeave={resetZoom}
                    >
                      <img
                        src={dealImages[currentIndex]}
                        alt={`Image ${currentIndex + 1}`}
                        onClick={() => window.open(dealImages[currentIndex], "_blank")}
                        className={`carousel-image ${isZooming ? "zoomed" : ""}`}
                        style={zoomStyle}
                      />
                    </div>
    </div>
  <button
    className="carousel-control prev"
    onClick={handlePrev}
  >
    &#10094;
  </button>
  <button
    className="carousel-control next"
    onClick={handleNext}
  >
    &#10095;
  </button>
</div>
</div>
) : (
<></>
)}
<br/>
{modalBody}<div/></ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>


const showModalPublishApprovedDeals = 
<Modal className="my-modal" show={modalsPublishApprovedDealsOpen} onHide={() => setModalPublishApprovedDealsIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => publishDealsClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalPublishApprovedDealsIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>



const showSendMessageModal = 
<Modal className="my-modal" show={modalsSendMessageOpen} onHide={() => setModalSendMessageIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}
    <form className="form">
      <div className="form-group">
        <input
          type="text"
          placeholder={t('Deals.My_message')}
          name="myMessage"
          value={myMessage}
          onChange={e => setMyMessage(e.target.value)}
          minLength="1"
        />
      </div>
    </form>
    </ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => sendMessageButtonClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalSendMessageIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>


      const tables =
      
      <Fragment>
      <DealsActions />
      
     
    { users_deals !== null ? (
 
<div>

{showTable}

</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      


function findByCriteria (name) {

  if ( searchCriteria === "Y") {
    setSearchField(name.replace(/\D/,''));
  }
  else if (searchCriteria === "D" || searchCriteria === "M") {
    setSearchField(name.replace(/(?!-)[^0-9.]/,''));
  }
  else {
    setSearchField(name);
  }


  if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
    setSearchField(name + "-")
}
else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
  setSearchField(name + "-")
}


  setCurrentPage(1);
}

function convertDate(dateCreation) {
  var finalDateCreation = ""
  if (dateCreation !== "") {
    const newDateCreation = dateCreation.substring(0, 10).split("-")
    finalDateCreation = newDateCreation[1] + '/' + newDateCreation[2] + '/' + newDateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = newDateCreation[2] + '/' + newDateCreation[1] + '/' + newDateCreation[0];
    }
  }
  return finalDateCreation;
}

function onClickPublishItem(newDeal) {
  setModalTitle(t('Deals.Publish_sure')+ ': "' + JSON.parse(JSON.stringify(newDeal.title))+'"?');
  setModalBody(
    t('Deals.Title')+" : " + (JSON.parse(JSON.stringify(newDeal.title)) !== '' ?
    JSON.parse(JSON.stringify(newDeal.title)) : t('Deals.None'))  +
   "\n"+t('Deals.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newDeal.initial_selling_price)) + "€"  +
   "\n"+t('Deals.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newDeal.negotiated_selling_price)) + "€"  +
   "\n"+t('Deals.Seller')+" : " + JSON.parse(JSON.stringify(newDeal.seller))
  );
  setModalFirstButton(t('Deals.Publish'));
  setModalSecondButton("");
 setModalSecondIsOpen(true);
}


function onClickSuspendItem(newDeal) {
setModalTitle(t('Deals.Suspend_sure')+ ': "' + JSON.parse(JSON.stringify(newDeal.title))+'"?');
setModalBody(
  t('Deals.Title')+" : " + (JSON.parse(JSON.stringify(newDeal.title)) !== '' ?
  JSON.parse(JSON.stringify(newDeal.title)) : t('Deals.None'))  +
  "\n"+t('Deals.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newDeal.initial_selling_price)) + "€"  +
  "\n"+t('Deals.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newDeal.negotiated_selling_price)) + "€"  +
  "\n"+t('Deals.Seller')+" : " + JSON.parse(JSON.stringify(newDeal.seller))
);
setModalFirstButton(t('Deals.Suspend'));
setModalSecondButton("");
setModalThirdIsOpen(true);
}

function onClickDeleteItem(newDeal) {
  setModalTitle(t('Deals.Delete_sure')+ ': "' + JSON.parse(JSON.stringify(newDeal.title))+'"?');
  setModalBody(
    t('Deals.Title')+" : " + (JSON.parse(JSON.stringify(newDeal.title)) !== '' ?
    JSON.parse(JSON.stringify(newDeal.title)) : t('Deals.None'))  +
    "\n"+t('Deals.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newDeal.initial_selling_price)) + "€"  +
    "\n"+t('Deals.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newDeal.negotiated_selling_price)) + "€"  +
    "\n"+t('Deals.Seller')+" : " + JSON.parse(JSON.stringify(newDeal.seller))
  );
  setModalFirstButton(t('Deals.Delete'));
  setModalSecondButton("");
 setModalFourthIsOpen(true);
}

function onClickSendMessageItem(newDeal) {
  setMyDeal({user: JSON.parse(JSON.stringify(newDeal.user)),
      advertId: JSON.parse(JSON.stringify(newDeal._id)),
          title: JSON.parse(JSON.stringify(newDeal.title)),
          receiverUsername: JSON.parse(JSON.stringify(newDeal.presenter)),
  })
  setModalTitle(t('Deals.Interested_article')+ ': "' + JSON.parse(JSON.stringify(newDeal.title))+'"?');
  setModalBody(t('Deals.Enter_message'));
  setModalFirstButton(t('Deals.Send'));
  setModalSecondButton("");
  setModalSendMessageIsOpen(true);
}



function showDetailsTable(deal) {
  var dateCreation = JSON.parse(JSON.stringify(deal.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(deal.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }
  setCurrentIndex(0);
  setDealImages([deal.attachment_name_first, deal.attachment_name_second, deal.attachment_name_third].filter(image => image !== ""))
  
  setModalTitle(JSON.parse(JSON.stringify(deal.title)));
  setModalBody(
    t('Deals.Presenter')+" : " + JSON.parse(JSON.stringify(deal.presenter))  +
    "\n"+t('Deals.Description')+" : " + JSON.parse(JSON.stringify(deal.description))  +
    "\n"+t('Deals.Further_information')+" : " + (JSON.parse(JSON.stringify(deal.further_information)) !== '' ?
    JSON.parse(JSON.stringify(deal.further_information)) : t('Deals.None')) +
    "\n"+t('Deals.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(deal.initial_selling_price)) + "€"  +
    "\n"+t('Deals.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(deal.negotiated_selling_price)) + "€"  +
   // "\n"+t('Deals.Offer_expiry_date')+" : " + convertDate(JSON.parse(JSON.stringify(deal.offer_expiry_date))) +
   "\n"+t('Deals.Seller')+" : " + JSON.parse(JSON.stringify(deal.seller)) +
  "\n"+t('Deals.Delivery_method')+" : " + t('Deals.'+JSON.parse(JSON.stringify(deal.delivery_method)))  +
  "\n"+t('Deals.Address')+" : " + (JSON.parse(JSON.stringify(deal.address)) !== '' ?
  JSON.parse(JSON.stringify(deal.address)) : t('Deals.None')) +
  "\n"+t('Deals.City')+" : " + (JSON.parse(JSON.stringify(deal.city)) !== '' ?
  JSON.parse(JSON.stringify(deal.city)) : t('Deals.None')) +
  "\n"+t('Deals.Postal_code')+" : " + (JSON.parse(JSON.stringify(deal.postal_code)) !== '' ?
  JSON.parse(JSON.stringify(deal.postal_code)) : t('Deals.None')) +
  "\n"+t('Deals.Country')+" : " + t('Country.'+JSON.parse(JSON.stringify(deal.country)))  +
  "\n"+t('Deals.State')+" : " + (JSON.parse(JSON.stringify(deal.status)) === "1" ? t('Deals.Pending') :
  JSON.parse(JSON.stringify(deal.status)) === "2" ? t('Deals.Approved') :
  JSON.parse(JSON.stringify(deal.status)) === "3" ? t('Deals.Online') :
  JSON.parse(JSON.stringify(deal.status)) === "4" ? t('Deals.Suspended') :
  JSON.parse(JSON.stringify(deal.status)) === "5" ? t('Deals.Deleted') : t('Deals.None')) +
  (deal.date_creation !== "" ? ("\n"+t('Deals.Date')+" : "  + finalDateCreation) : "")
  );
    setModalFirstButton("");
    setModalSecondButton(t('Deals.Ok'));
   setModalDetailIsOpen(true);
  }


  function publishButtonClicked() {
      updateDeal(dealId, 3, user._id, false);
      setModalSecondIsOpen(false);
  }
  
  function suspendButtonClicked() {
      updateDeal(dealId, 4, user._id, false);
      setModalThirdIsOpen(false);
  }
  
  function deleteButtonClicked() {
      updateDeal(dealId, 5, user._id, false);
      setModalFourthIsOpen(false);
  }
  
  function publishDealsClicked() {
    publishDeals(user._id);
    setModalPublishApprovedDealsIsOpen(false);
  }

  function sendMessageButtonClicked() {
      addAdvertMessage({senderId: user._id,
          receiverId: myDeal.user,
              advertId: myDeal.advertId,
                  category: "Deal",
                      title: myDeal.title,
                          senderUsername: user.username,
                          senderAdminUsername: "",
                              receiverUsername: myDeal.receiverUsername,
                                  text: myMessage,
                                  history : history
      })
      setModalSendMessageIsOpen(false);
    }


const dropDownSelected = event => {
  if (JSON.parse(event).index === "1") {
    showDetailsTable(JSON.parse(event).deal);
  }
  else if (JSON.parse(event).index === "3") {
    setDealId(JSON.parse(event).deal._id)
    onClickPublishItem(JSON.parse(event).deal);
  }
  else if (JSON.parse(event).index === "4") {
    setDealId(JSON.parse(event).deal._id)
    onClickSuspendItem(JSON.parse(event).deal);
  }
  else if (JSON.parse(event).index === "5") {
    setDealId(JSON.parse(event).deal._id)
    onClickDeleteItem(JSON.parse(event).deal);
  }
  else if (JSON.parse(event).index === "6") {
    setDealId(JSON.parse(event).deal._id)
    onClickSendMessageItem(JSON.parse(event).deal);
  }
}


const dropDownSelectedCountry = event => {
  if (event && JSON.parse(event).index) {
    setCountry(JSON.parse(event).index);
    setCountryLabel(event);
  }
  else {
    setCountry("");
    setCountryLabel("");
  }
}


return loading && users_deals === null ? (
  <Spinner />
) : (
  
  <Fragment>

{showModalPublish}
    {showModalSuspend}
    {showModalDelete}
    {showModalDetail}
    {showModalPublishApprovedDeals}
    {showSendMessageModal}
  {tables}



</Fragment>
);
};


Deals.propTypes = {
  getDealForClients: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  publishDeals: PropTypes.func.isRequired,
  addAdvertMessage: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  deal: state.deal,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getDealForClients, updateDeal, publishDeals, addAdvertMessage, getSponsorships }
)(Deals);


