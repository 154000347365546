import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { /*getSponsorships,*/ repositioningUser, pay, paySucceded, accept_membership, payAffiliationProgram } from '../../actions/sponsorship';
import { useSpring, animated } from 'react-spring';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
/*import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';*/
import * as moment from 'moment-timezone';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import useWindowDimensions from '../layout/UseWindowDimensions';
import Confetti from 'react-confetti';


const Notification = ({ repositioningUser, payAffiliationProgram, pay, paySucceded, accept_membership, auth: { user, isAuthenticated, loading, theme }, sponsorship: { sponsorships, deleted_affiliation, full_matrix, old_user, candidate_warning, bonus_genealogy, old_users, payed, accept_membership_user } }) => {



  const stripe = useStripe();
  const elements = useElements();

  const [priceMmembership, setPriceMembership] = useState(405);
  const price_membership = 405;

  useEffect(() => {
    if (isAuthenticated) {
      if (elements) {
        elements.getElement(CardElement).clear()
      }
      if (user) {
        if (user.country === "BE") {
          setPriceMembership(price_membership + (price_membership * 0.21))
      }
      else if (user.country === "FR") {
        setPriceMembership(price_membership + (price_membership * 0.2))
      }
      else if (user.country === "GB") {
        setPriceMembership(price_membership + (price_membership * 0.2))
      }
      else if (user.country === "IT") {
        setPriceMembership(price_membership + (price_membership * 0.22))
      }
      else if (user.country === "LU") {
        setPriceMembership(price_membership + (price_membership * 0.17))
      }
      else if (user.country === "CH") {
        setPriceMembership(price_membership + (price_membership * 0.081))
      }
      else if (user.country === "GP") {
        setPriceMembership(price_membership + (price_membership * 0.085))
      }
      else if (user.country === "MQ") {
        setPriceMembership(price_membership + (price_membership * 0.085))
      }
      else if (user.country === "RE") {
        setPriceMembership(price_membership + (price_membership * 0.085))
      }
      else if (user.country === "GF") {
        //setPriceMembership(price_membership + 0)
      }
      else {
        setPriceMembership(price_membership + (price_membership * 0.2))
      }
    }
    }
  }, [isAuthenticated, elements, user]);


 const { t } = useTranslation();

 const { height, width } = useWindowDimensions();
 var countMember = 0;
for(var i = 0; i < sponsorships.length; ++i){
       if(sponsorships[i].status.charAt(0) === "M") {
         countMember++;
       }
}

  
const [signature, setSignature] = useState(false);
const [signatureAccept, setSignatureAccept] = useState(false);
const fade = useSpring({
  /*from: {
    transform: 'translate3d(0, -500px, 0)'
  },
  to: {
    transform: 'translate3d(0, 0px, 0)'
  }*/

  opacity : /*signature*/ signatureAccept ? 1 : 0
})

const [modalsOpen, setModalIsOpen] = useState(false);
const [secondModalsOpen, setSecondModalIsOpen] = useState(false);
const [thirdModalsOpen, setThirdModalIsOpen] = useState(false);


const [showConfetii, setShowConfetii] = useState(false);


const [modalTitle, setModalTitle] = useState("");
const [modalBody, setModalBody] = useState("");
const [modalFirstButton, setModalFirstButton] = useState("");

const iframeStyles = {
  base: {
    color: theme === 'light' ? "#32325d" : "#fff",
    fontSize: "16px",
    iconColor: theme === 'light' ? "#32325d" : "#fff",
    "::placeholder": {
      color: theme === 'light' ? "#aab7c4" : "#87bbfd"
    }
  },
  invalid: {
    iconColor: theme === 'light' ? "#fa755a" : "#FFC7EE",
    color: theme === 'light' ? "#fa755a" : "#FFC7EE"
  },
  complete: {
    iconColor: theme === 'light' ? "#51aa4d" : "#cbf4c9"
  }
};

const CARD_ELEMENT_OPTIONS = {
 /* style: {
    base: {
      'color': '#32325d',
      'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
      'fontSmoothing': 'antialiased',
      'fontSize': '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },*/
  iconStyle: "solid",
  style: iframeStyles,
  hidePostalCode: true
};

function repositioning() {
  setModalIsOpen(false);
  if (full_matrix && countMember >= 4 && !old_user) {
    repositioningUser(user._id)
  }
}

const pay_year = async e => {
  if (!stripe || !elements) {
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return;
  }
  //setSecondModalIsOpen(false);
  if (user && !user.payed) {
    await pay(user._id);
  //  getSponsorships(user._id);
  //console.log(elements)
  const result = await stripe.confirmCardPayment(payed, {
    payment_method: {
      card: elements.getElement(CardElement),
      billing_details: {
        email: user.email,
      },
    },
  });
  if (result.error) {
    // Show error to your customer (e.g., insufficient funds)
  //  console.log(result.error.message);
  refused_payment();
  } else {
    // The payment has been processed!
    if (result.paymentIntent.status === 'succeeded') {
      accepted_payment();
      await paySucceded(user._id);
      elements.getElement(CardElement).clear()
    //  console.log('Money is in the bank!');
      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
    }
  }
  }
}


const pay_provision_membership = async e => {
    await payAffiliationProgram(user.parent_id, user._id);
}

const pay_annual_subscription = async e => {
  await accept_membership(user._id);
}

/*function renew() {
  setSecondModalIsOpen(true);
  setModalTitle(t('Notification.Validate_paiement'))
  //setModalBody(t('Notification.Must_pay_pre_opening'))
  setModalBody(t('Notification.Must_pay_first_time'))
  setModalFirstButton(t('Notification.Pay'));
}*/

function renew_year() {
  setSecondModalIsOpen(true);
  setModalTitle(t('Notification.Renew_contract'))
  setModalBody(t('Notification.Must_pay'))
  setModalFirstButton(t('Notification.Renew'));
}

function accepted_payment() {
  setShowConfetii(true);
  setThirdModalIsOpen(true);
  setModalTitle(t('Notification.Congratulations'))
  setModalBody(t('Notification.Payment_successful'))
  setModalFirstButton(t('Notification.Ok'));
}

function close_third_popup() {
  setThirdModalIsOpen(false);
  setShowConfetii(false);
}

function refused_payment() {
  setThirdModalIsOpen(true);
  setModalTitle(t('Notification.Warning'))
  setModalBody(t('Notification.Payment_refused'))
  setModalFirstButton(t('Notification.Ok'));
}

function handleSignatureChange  (changeEvent) {
  setSignature(!signature);
}

function handleSignatureAcceptChange  (changeEvent) {
  setSignatureAccept(!signatureAccept);
}


const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{full_matrix ? t('Notification.Repositioning_conditions') : t('Notification.No_candidates')}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{ full_matrix ? (countMember < 4 ? t('Notification.Member_partners1') + " " + 4 + " " + t('Notification.Member_partners2') : t('Notification.Condition1') + "\n\n" + t('Notification.Condition2') + "\n\n" + t('Notification.Condition3') + "\n\n") : deleted_affiliation <= 3 ? t('Notification.Add_members') : "" }
</ModalBody>
<ModalFooter as="footer">
{
full_matrix && countMember >= 4 ?
<div>
<button className="btn btn-primary" onClick={() => repositioning()}>{t('Notification.Accept')}</button>
</div> : 
<button className="btn btn-primary" onClick={() => repositioning()}>{t('Notification.Ok')}</button>

           }
</ModalFooter>
</Modal>



 
const showSecondModal = 
<Modal className="my-modal" show={secondModalsOpen} onHide={() => setSecondModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}
</ModalBody>
<ModalFooter as="footer">
<button className="btn btn-primary" onClick={() => pay_year()}>{modalFirstButton}</button>
</ModalFooter>
</Modal>



const showThirdModal = 
<Modal className="my-modal" show={thirdModalsOpen} onHide={() => close_third_popup()} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}
</ModalBody>
<ModalFooter as="footer">
<button className="btn btn-primary" onClick={() => close_third_popup()}>{modalFirstButton}</button>
</ModalFooter>
</Modal>


  var currentDate = moment().tz("Europe/London").format("YYYY-MM-DD");
  var diffMonth = false
  var diffDays = false
  if (user && user.date_creation) {
    diffMonth = moment(currentDate, "YYYY-MM-DD").add(-2, 'month').isAfter(user.date_creation, 'month');
  //  diffDays = moment(currentDate, "YYYY-MM-DD").add(-19, 'day').isAfter(user.date_creation, 'day');
  diffDays = moment(currentDate, "YYYY-MM-DD").add(1, 'day').isAfter(user.date_creation, 'day');
  }
  var diffMonthRepositioned = false;
  if (user && user.date_repositioning) {
    diffMonthRepositioned = moment(currentDate, "YYYY-MM-DD").add(-2, 'month').isAfter( user.date_repositioning, 'month')
  }
  if (user && user.date_repositioning && old_users) {
  //  diffDays = moment(currentDate, "YYYY-MM-DD").add(-19, 'day').isAfter(newOldUsers[0].date_creation, 'day');
  diffDays = moment(currentDate, "YYYY-MM-DD").add(1, 'day').isAfter(old_users[0].date_creation, 'day');
  }

  var curr = null
 // var currRepositioning = null
  var diffYear = null
 // var diffYearRepositioning = null

  if (user && user.date_creation) {
    /* Becareful here when we change month to day*/
  curr = parseInt(moment().tz("Europe/London").format("MM")) === (parseInt(user.date_creation.slice(5, 7)) + 1)
 // currRepositioning = moment().tz("Europe/Paris").format("MM-DD").slice(-5) === user.date_repositioning.slice(-5)
  diffYear = moment(currentDate, "YYYY-MM-DD").isAfter(user.date_creation, 'month');
//  diffYearRepositioning = moment(currentDate, "YYYY-MM-DD").isAfter(user.date_repositioning, 'day');  
}

if (user && user.date_repositioning && old_users) {
  /* Becareful here when we change month to day*/
curr = parseInt(moment().tz("Europe/London").format("MM")) === (parseInt(old_users[0].date_creation.slice(5, 7)) + 1)
// currRepositioning = moment().tz("Europe/Paris").format("MM-DD").slice(-5) === user.date_repositioning.slice(-5)
diffYear = moment(currentDate, "YYYY-MM-DD").isAfter(old_users[0].date_creation, 'month');
//  diffYearRepositioning = moment(currentDate, "YYYY-MM-DD").isAfter(user.date_repositioning, 'day');  
}



var diffDaysRepositionning = false
if (user && user.date_repositioning) {
  diffDaysRepositionning = moment(currentDate, "YYYY-MM-DD").add(7, 'day').isAfter( user.date_repositioning, 'day')
}


  return (
  
   

    <Fragment>

{
  (user && user.payed_status === 0 && !accept_membership_user)?
  <div 
style={{
 display: "flex",
justifyContent: "center",
alignItems: "center"
}}
className="alert alert-light" role="alert">
        <label><p className="h6">IV. {t('Notification.Contract_signature')}</p>
        <input style={{marginRight:"5px"}}  type="checkbox" value="C" 
                      checked={signatureAccept === true} 
                      onChange={handleSignatureAcceptChange}
                      />
          {t('Notification.Contract_accept6')} <Link to='/terms-conditions' className="alert-link" target="_blank">{t('Notification.Terms_conditions')}</Link> {t('Notification.Contract_accept7')}
          <br/>
           {t('Notification.Contract_accept8')}<Link to='/terms-conditions' className="alert-link" target="_blank"> {t('Notification.Terms_conditions')}</Link> {t('Notification.Contract_accept9')}
          
      </label>

      <div style={{display: user && signatureAccept ? "flex" : "none", marginRight: "50px", marginLeft: "50px",
      justifyContent: "center",
      alignItems: "center"}}>
            <animated.div style={fade}>
      <br/>
      <button type="button" className="btn btn-success" disabled={!stripe} onClick={() => pay_annual_subscription() /*renew()*/}>{t('Notification.Pay_annual_subscription')}</button>
      
              </animated.div>
              </div>
</div>
: null }

{
  (user && user.payed_status === 4)?
  <div 
style={{
 display: "flex",
justifyContent: "center",
alignItems: "center"
}}
className="alert alert-light" role="alert">
        <label><p className="h6">IV. {t('Notification.Contract_signature')}</p>
        <input style={{marginRight:"5px"}}  type="checkbox" value="C" 
                      checked={signatureAccept === true} 
                      onChange={handleSignatureAcceptChange}
                      />
          {t('Notification.Contract_accept1')} <Link to='/policies-procedures' className="alert-link" target="_blank">{t('Notification.Policies_procedures')}</Link> {t('Notification.Contract_accept2')}
          <br/>
          {t('Notification.Contract_accept3')}<Link to='/policies-procedures' className="alert-link" target="_blank"> {t('Notification.Policies_procedures')}</Link> {t('Notification.Contract_accept4')}
          
      </label>

      <div style={{display: user && signatureAccept ? "flex" : "none", marginRight: "50px", marginLeft: "50px",
      justifyContent: "center",
      alignItems: "center"}}>
            <animated.div style={fade}>
      <br/>
      <button type="button" className="btn btn-success" disabled={!stripe} onClick={() => pay_provision_membership() /*renew()*/}>{t('Notification.Pay_membership_provision')}</button>
      
              </animated.div>
              </div>
</div>
: null }

{
  user && user.payed_status === 2?
  <div 
style={{
 display: "flex",
justifyContent: "center",
alignItems: "center"
}}
className="alert alert-danger" role="alert">
        <p> {t('Notification.Pay_mailbox') + ": " + user.email}</p>
     </div>
: null}


{user && user.qualified === 0 && (diffMonth || diffMonthRepositioned) ? 
<div 
style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}}
className="alert alert-danger" role="alert">
  <strong> {t('Notification.Warning') + "..."}</strong>
  <pre className="tab"> </pre>
  {diffMonth ? t('Notification.Must_qualify') : t('Notification.Must_qualify2')}
</div>
: null}

{ /*user && diffDays && !user.payed && !curr ?
    <div 
style={{
  display: "flex", //UNCOMMENT pre-opening ON FIRST MONTH
 // display: "none", // comment this
  justifyContent: "center",
  alignItems: "center"
}}
className="alert alert-danger" role="alert">
<strong> {t('Notification.Warning') + "..."}</strong>
  <pre className="tab"> </pre>
  {t('Notification.Must_pay_pre_opening') + ","}
    <pre className="tab"> </pre>
    <a href="#!" className="alert-link"  onClick={() => renew()}>
   {"\n"+t('Notification.Pay')}
  </a>
</div>
: null */}

{  // UNCOMMENT pre-opening ON FIRST MONTH
  user && diffDays && !user.payed /* TO UNCOMMENT && !curr */ ?

  <div 
style={{
//display: "flex", //UNCOMMENT pre-opening ON FIRST MONTH
 display: "none", // comment this
justifyContent: "center",
alignItems: "center"
}}
className="alert alert-light" role="alert">
        <label><p className="h6">IV. {t('Notification.Contract_signature')}</p>
        <input style={{marginRight:"5px"}}  type="checkbox" value="C" 
                      checked={signature === true} 
                      onChange={handleSignatureChange}
                      />
          {t('Notification.Contract_accept1')} <Link to='/policies-procedures' className="alert-link" target="_blank">{t('Notification.Policies_procedures')}</Link> {t('Notification.Contract_accept2') + " " + t('Notification.Contract_accept3')}<Link to='/policies-procedures' className="alert-link" target="_blank"> {t('Notification.Policies_procedures')}</Link> {t('Notification.Contract_accept4')}
          
      </label>
</div>
: null }

<div style={{display: user && diffDays && !user.payed /*TO UNCOMMENT && !curr*/ && signature ? "flex" : "none", marginRight: "50px", marginLeft: "50px",
justifyContent: "center",
alignItems: "center"}}>

<div className="card card-body d-flex justify-content-center my-card text-center" style={{maxWidth: "500px"}}>


<CardElement options={CARD_ELEMENT_OPTIONS} />
<animated.div style={fade}>



<br/>
<button type="button" className="btn btn-success" disabled={!stripe} onClick={() => pay_year() /*renew()*/}>{t('Notification.Pay_membership_fee')} - {(priceMmembership).toFixed(2)}€</button>


{/*
<StripeCheckout stripeKey="" token={() => pay(user._id)} name={t('Notification.Membership_provision')} amount="45500">
<button type="button" className="btn btn-success"/* onClick={() => renew()}*//*>{t('Notification.Pay_membership_fee')}</button>
  </StripeCheckout>*/}
        </animated.div></div></div>
      

{  // UNCOMMENT pre-opening ON FIRST MONTH AFTER ONE YEAR
  
(user && diffYear && curr && !user.payed) ? //|| (diffYearRepositioning && currRepositioning))
    <div 
style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}}
className="alert alert-danger" role="alert">
<strong> {t('Notification.Warning') + "..."}</strong>
  <pre className="tab"> </pre>
  {t('Notification.Must_pay') + ","}
    <pre className="tab"> </pre>
    <a href="#!" className="alert-link"  onClick={() => renew_year()}>
   {"\n"+t('Notification.Renew')}
  </a>
</div>
: null
}




{user && candidate_warning > 0 ? 
    <div 
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
    className="alert alert-danger" role="alert">
  <strong> {t('Notification.Warning') + "..."}</strong>
  <pre className="tab"> </pre>
      {t('Notification.Must_help1') + " " + candidate_warning + " " + (candidate_warning === 1 ? t('Notification.Must_help2') : t('Notification.Must_help2s'))}
    </div>
    : null}

{/*user && old_user ? 
  <div 
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }}
  className="alert alert-secondary" role="alert">
<strong> {t('Notification.Warning') + "..."}</strong>
<pre className="tab"> </pre>
    {t('Notification.Not_qualified')}
  </div>
: <></>*/}


{
full_matrix && !old_user && user && (user.notification_full_matrix <= 5 || countMember >= 4) && diffDaysRepositionning ? 
<Fragment>
<div 
style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}}
className="alert alert-primary" role="alert">
<strong> {t('Notification.Congratulations') + "..."}</strong>
<pre className="tab"> </pre>
    {t('Notification.Full_matrix3') + (countMember >= 4 ? (", " + t('Notification.Full_matrix4')) : (". " + t('Notification.Full_matrix5' + (user.status.charAt(0) === "M" ? t('Sponsorship.Actif_member') + user.status.substring(1) : ""))))}
    {
      countMember >= 4 ?
      <>
      <pre className="tab"> </pre>
      <span>
      <a href="#!" className="alert-link" onClick={() => setModalIsOpen(true)}>
     {"\n" + t('Notification.Start') + "..."}
    </a>
    </span>
    </>
    : null
    }

</div>
</Fragment>
: null}





{user && bonus_genealogy > 0 ? 
  <div 
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }}
  className="alert alert-success" role="alert">
<strong> {t('Notification.Congratulations') + "..."}</strong>
<pre className="tab"> </pre>
    {t('Notification.Qualified_repositioning')}
  </div>
  : null}


<div style={{display: showConfetii ? "" : "none"}}>
<Confetti
          recycle={showConfetii}
          numberOfPieces={priceMmembership}
          width={width}
          height={height}
        /></div>


{showModal}
{showSecondModal}
{showThirdModal}


{/*
<Modal className="my-modal" show={secondModalsOpen} onHide={() => setSecondModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}
 <CardElement options={CARD_ELEMENT_OPTIONS} />
</ModalBody>
<ModalFooter as="footer">
<button className="btn btn-primary" onClick={() => pay_year()}>{modalFirstButton}</button>
</ModalFooter>
</Modal>
*/}
    </Fragment>
    
  );
  
};

Notification.propTypes = {
  auth: PropTypes.object.isRequired,
  sponsorship: PropTypes.object.isRequired,
  repositioningUser: PropTypes.func.isRequired,
  payAffiliationProgram: PropTypes.func.isRequired,
  pay: PropTypes.func.isRequired,
  accept_membership: PropTypes.func.isRequired,
  paySucceded: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  sponsorship: state.sponsorship,
});

export default connect(
  mapStateToProps,
  { repositioningUser, payAffiliationProgram, pay, paySucceded, accept_membership }
)(Notification);
