import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, loadUser } from '../../actions/auth';
import setAuthToken from '../../utils/setAuthToken';
import Nav from 'react-bootstrap/Nav';
import NavbarBootstrap from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Overlay from 'react-bootstrap/Overlay';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import EnglandFlag from '../../img/england.png';
import FranceFlag from '../../img/france.png';
import ItalyFlag from '../../img/italy.png';
import { useLocation } from "react-router-dom";
import Toggle from './Toggle';

import useWindowDimensions from '../layout/UseWindowDimensions';

import LogoLight from '../../img/icon_blank.png';
import LogoDark from '../../img/icon_dark.png';

import { useHistory } from 'react-router-dom';

import { Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import NavbarLogout from './NavbarLogout';

const Navbar = ({ auth: { user, isAuthenticated, loading, theme }, chat: { chats }, logout, loadUser }) => {


 const { t } = useTranslation();
 let history = useHistory();
const [newTheme] = useState("dark");

const [isFocused, setIsFocused] = useState(false);
const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);


const { /*height,*/ width } = useWindowDimensions();

useEffect(() => {
  
  const handleInvalidToken = e => {
    if (e.key === 'token' && e.oldValue && !e.newValue) {
      if (isFocused) {
        setAuthToken(e.oldValue);
      }
      else {
        logout();
      }
    }
    else if (e.key === 'token' && !e.oldValue && e.newValue){
        setAuthToken(e.newValue);
        loadUser();
      }
    }

    const handleOnFocus = () => {
      setIsFocused(true);
    };

    const handleOnBlur = () => {
      setIsFocused(false);
    };

  window.addEventListener('storage', handleInvalidToken)
  window.addEventListener('focus', handleOnFocus)
  window.addEventListener('blur', handleOnBlur)
  return function cleanup() {
    window.removeEventListener('storage', handleInvalidToken)
    window.removeEventListener('focus', handleOnFocus)
    window.removeEventListener('blur', handleOnBlur)
  }
}, [logout, loadUser, isFocused])


useEffect(() => {
  let newTotalUnreadMessages = 0;
for (let i = 0; i < chats?.length; i++) {
  if (chats[i].lastSender === user?.email) {
    continue;
  }
  newTotalUnreadMessages += chats[i].seen;
}
setTotalUnreadMessages(newTotalUnreadMessages);
}, [chats, user]) 



const location = useLocation();


 const handleClick = /*async*/ (lang) => {
 // function handleClick(lang) {
  /*await*/ i18next.changeLanguage(lang)
   // await getArticles(2, 10, user.country, t('Language.Lang'));

   /* if (window.location.pathname === '/dashboard') {
      await window.location.reload();
    } */
  }



/*
  const adminLinks = (
    <ReactBootstrap.NavDropdown title={t('NavBar.Admin')} id="collasible-nav-dropdown">
        <Link to='/admin/dashboard-admin'><ReactBootstrap.NavDropdown.Item href="#dashboard-admin">{t('NavBar.AdminDashboard')}</ReactBootstrap.NavDropdown.Item></Link>
        <ReactBootstrap.NavDropdown.Divider />
        <Link to='/admin/sponsorship-tree-admin'><ReactBootstrap.NavDropdown.Item href="#sponsorship-tree-admin">{t('NavBar.Sponsorship')}</ReactBootstrap.NavDropdown.Item></Link>
  </ReactBootstrap.NavDropdown>
  ) */
  const authLinks = (

    <NavbarBootstrap collapseOnSelect expand="xl" /*bg={theme === "light" ? "light" : "dark"}*/ className={!isAuthenticated && width > 1000 && (location.pathname === "/" || location.pathname === "/login") ? "color-nav-transparent text-primary" : "color-nav text-primary"} variant={newTheme === "light" ? "light" : "dark"}>

{/*<div
    style={{
      marginLeft: "10px",
      marginRight: "10px"
    }}>
         
         <h6>
         { user ? t('NavBar.First_name') + ": " + user.first_name : ""}
         </h6>
         <h6>
         { user ? t('NavBar.Last_name') + ": " + user.last_name : ""}
         </h6>
         </div>
         <div
    style={{
      marginLeft: "10px",
      marginRight: "60px"
    }}>
         <h6>
         { user ? t('NavBar.Username') + ": " +  user.username : ""}
         </h6>
         <h6>
         { t('NavBar.Status') + ": " + (user && user.status === "C" ?  t('NavBar.Candidate') :
         user && user.status === "J" ?  t('NavBar.Joker') :
            user && user.status && user.status.charAt(0) === "M" ? t('NavBar.Member') + user.status.substring(1) : "")}
         </h6>
  </div>*/}
{/*<NavbarBootstrap.Brand href="#home">React-Bootstrap</NavbarBootstrap.Brand>*/}
 
{ user && user.role === 0 ? 
  <NavbarBootstrap.Brand><Link to='/admin/affiliations'><div className="navbar-brand" style={{width: 150, height: 'auto'}}>
<img src={newTheme === "light" ? LogoDark : LogoLight} alt="Atrakxion icon"  className="img-fluid" /> </div>
</Link></NavbarBootstrap.Brand>
/*SHOW MENU ON LOGIN : user && user.role !== 0 ? 
<NavbarBootstrap.Brand><Link to='/'>
{/*<i className='fas fa-code' /> {t('NavBar.Atrakxion')}*//*}
<div className="navbar-brand" style={{width: 150, height: 'auto'}}>
<img src={newTheme === "light" ? LogoDark : LogoLight} alt="Atrakxion icon"  className="img-fluid" /> </div>
</Link></NavbarBootstrap.Brand>*/
:
<NavbarBootstrap.Brand><Link className="navbar-brand" to='/'>
{/*<i className='fas fa-code' /> {t('NavBar.Home')}*/}
<div className="navbar-brand" style={{width: 150, height: 'auto'}}>
<img src={newTheme === "light" ? LogoDark : LogoLight} alt="Atrakxion icon"  className="img-fluid" /> </div>
</Link></NavbarBootstrap.Brand>
}


  <NavbarBootstrap.Toggle aria-controls="responsive-navbar-nav" />
  <NavbarBootstrap.Collapse id="responsive-navbar-nav">
     <Nav className="ml-auto" activeKey={location.pathname}>

     <OverlayTrigger placement="bottom" overlay={isAuthenticated ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
   <Link to= {isAuthenticated ? '/dashboard' : '#'} onClick= { () => {
    if (!isAuthenticated) {
    history.push({
    pathname: '/login' })
    }
    }
  }
     ><Nav.Link className="px-2 py-2" href='#dashboard'><Nav style={{ fontWeight: 'bold', color : location.pathname === '/dashboard' ? newTheme === "light" ? '#bc9f51' : '#edd99a' : ''}}>{t('NavBar.Home')}</Nav></Nav.Link></Link>
    </OverlayTrigger>

    <OverlayTrigger placement="bottom" overlay={isAuthenticated ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
   <Link to= {isAuthenticated ? '/community' : '#'} onClick={ isAuthenticated ? null : () => { 
    history.push({
    pathname: '/login' })
  }} ><Nav.Link className="px-2 py-2" href='#community'><Nav style={{fontWeight: 'bold', color : location.pathname === '/community' ? newTheme === "light" ? '#bc9f51' : '#edd99a' : ''}}>{t('NavBar.Community')}</Nav></Nav.Link></Link>
    </OverlayTrigger>



   
   {/*
  
 <NavDropdown className="px-2 py-2" style={{fontWeight: 'bold'}} title={t('NavBar.About')} id="collasible-nav-dropdown">
      <Link to='/presentation'><NavDropdown.Item style={{fontWeight: 'bold', color : location.pathname === '/presentation' ? theme === "light" ? 'black' : 'white' : ''}} href="#presentation">{t('NavBar.Presentation')}</NavDropdown.Item></Link>
        <NavDropdown.Divider />
        <Link to='/privacy-policy'><NavDropdown.Item style={{fontWeight: 'bold', color : location.pathname === '/privacy-policy' ? theme === "light" ? 'black' : 'white' : ''}} href="#privacy-policy">{t('NavBar.Privacy_policy')}</NavDropdown.Item></Link>
      <NavDropdown.Divider />
        <Link to='/policies-procedures'><NavDropdown.Item style={{fontWeight: 'bold', color : location.pathname === '/policies-procedures' ? theme === "light" ? 'black' : 'white' : ''}} href="#policies-procedures">{t('NavBar.Policies_procedures')}</NavDropdown.Item></Link>
      </NavDropdown> */}


    

          <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
      <Link to= {isAuthenticated ? '/all-deals' : '#'}><Nav.Link className="px-2 py-2"
       href={user && user.status !== "C" ? '#all-deals' : "#"} onClick={isAuthenticated ? null : () => { 
    history.push({
    pathname: '/login' })
  }}><Nav style={{fontWeight: 'bold', color : location.pathname === '/all-deals' ? newTheme === "light" ? '#bc9f51' : '#edd99a' : ''}}>{t('NavBar.Good_deals')}</Nav></Nav.Link></Link>
      </OverlayTrigger>
        
       
        <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
      <Link to= {isAuthenticated ? '/all-purchases' : '#'}><Nav.Link className="px-2 py-2"
       href={user && user.status !== "C" ? '#all-purchases' : "#"} onClick={isAuthenticated ? null : () => { 
    history.push({
    pathname: '/login' })
  }}><Nav style={{fontWeight: 'bold', color : location.pathname === '/all-purchases' ? newTheme === "light" ? '#bc9f51' : '#edd99a' : ''}}>{t('NavBar.Grouped_purchases')}</Nav></Nav.Link></Link>
      </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
      <Link to= {isAuthenticated ? '/all-advertisements' : '#'}><Nav.Link className="px-2 py-2"
       href={user && user.status !== "C" ? '#all-advertisements' : "#"} onClick={isAuthenticated ? null : () => { 
    history.push({
    pathname: '/login' })
  }}><Nav style={{fontWeight: 'bold', color : location.pathname === '/all-advertisements' ? newTheme === "light" ? '#bc9f51' : '#edd99a' : ''}}>{t('NavBar.Member_to_member')}</Nav></Nav.Link></Link>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={isAuthenticated ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
      <Link to= {isAuthenticated ? '/messaging' : '#'}><Nav.Link className="px-2 py-2"
       href="#messaging" onClick={isAuthenticated ? null : () => {
    history.push({
    pathname: '/login' })
  }}><Nav style={{fontWeight: 'bold', color : location.pathname === '/messaging' ? newTheme === "light" ? '#bc9f51' : '#edd99a' : ''}}>{t('NavBar.Messaging') + " (" + totalUnreadMessages + ")"}</Nav></Nav.Link></Link>
</OverlayTrigger>

      {/*
            <NavDropdown style={{fontWeight: 'bold'}} title={t('NavBar.About')} id="collasible-nav-dropdown">
      <Link to='/presentation'><NavDropdown.Item style={{fontWeight: 'bold', color : location.pathname === '/presentation' ? '#edd99a' : ''}} href="#presentation">{t('NavBar.Presentation')}</NavDropdown.Item></Link>
        <NavDropdown.Divider />
        <Link to='/privacy-policy'> <NavDropdown.Item style={{fontWeight: 'bold', color : location.pathname === '/privacy-policy' ? '#edd99a' : ''}} href="#privacy-policy">{t('NavBar.Privacy_policy')}</NavDropdown.Item></Link>
        <Link to='/policies-procedures'> <NavDropdown.Item style={{fontWeight: 'bold', color : location.pathname === '/policies-procedures' ? '#edd99a' : ''}} href="#policies-procedures">{t('NavBar.Policies_procedures')}</NavDropdown.Item></Link>
      </NavDropdown>
      */}
      {/*user && user.role === 0 ? 
      adminLinks : <Fragment>
      </Fragment>*/}




        <NavDropdown className="px-2 py-2" alignCenter style={{fontWeight: 'bold'}} title={t('NavBar.Affiliation_program')} id="collasible-nav-dropdown">
        <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" && user.status !== "A" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
        <Link to= {isAuthenticated ? '/presentation' : '#'} onClick={ isAuthenticated ? null : () => {
      history.push({
      pathname: '/login' })
    }}><NavDropdown.Item href={user && user.status !== "C" && user.status !== "A" ? '#presentation' : "#"}><Nav style={{fontWeight: 'bold', color : location.pathname === '/presentation' ? theme === "light" ? 'black' : 'white' : ''}}>{t('NavBar.Presentation')}
    </Nav></NavDropdown.Item></Link>     
    </OverlayTrigger>
        <NavDropdown.Divider />
        <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" && user.status !== "A" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
          <Link to= {isAuthenticated ? '/policies-procedures' : '#'} onClick={ isAuthenticated ? null : () => { 
      history.push({
      pathname: '/login' })
    }}><NavDropdown.Item href={user && user.status !== "C" && user.status !== "A" ? '#policies-procedures' : "#"}><Nav style={{fontWeight: 'bold', color : location.pathname === '/policies-procedures' ? theme === "light" ? 'black' : 'white' : ''}}>{t('NavBar.Policies_procedures')}</Nav></NavDropdown.Item></Link>
    </OverlayTrigger>
          <NavDropdown.Divider />
          <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" && user.status !== "A" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
          <Link to= {isAuthenticated ? '/training' : '#'} onClick={ isAuthenticated ? null : () => { 
      history.push({
      pathname: '/login' })
    }}><NavDropdown.Item href={user && user.status !== "C" && user.status !== "A" ? '#training' : "#"}><Nav style={{fontWeight: 'bold', color : location.pathname === '/training' ? theme === "light" ? 'black' : 'white' : ''}}>{t('NavBar.Training')}</Nav></NavDropdown.Item></Link>
        </OverlayTrigger>
          <NavDropdown.Divider />
          <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" && user.status !== "A" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
          <Link to= {isAuthenticated ? '/questions-answers' : '#'} onClick={ isAuthenticated ? null : () => { 
      history.push({
      pathname: '/login' })
    }}><NavDropdown.Item href={user && user.status !== "C" && user.status !== "A" ? '#questions-answers' : "#"}><Nav style={{fontWeight: 'bold', color : location.pathname === '/questions-answers' ? theme === "light" ? 'black' : 'white' : ''}}>{t('NavBar.Questions_answers')}</Nav></NavDropdown.Item></Link>
        </OverlayTrigger>
        <NavDropdown.Divider />
          <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" && user.status !== "A" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
          <Link to= {isAuthenticated ? '/interactive-forecast' : '#'} onClick={ isAuthenticated ? null : () => { 
      history.push({
      pathname: '/login' })
    }}><NavDropdown.Item href={user && user.status !== "C" && user.status !== "A" ? '#interactive-forecast' : "#"}><Nav style={{fontWeight: 'bold', color : location.pathname === '/interactive-forecast' ? theme === "light" ? 'black' : 'white' : ''}}>{t('NavBar.Interactive_forecast')}</Nav></NavDropdown.Item></Link>
        </OverlayTrigger>
        <NavDropdown.Divider />
        <OverlayTrigger placement="bottom" overlay={isAuthenticated ? (user && user.status !== "C" && user.status !== "A" ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Access_denied')}</Tooltip>) : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
          <Link to= {isAuthenticated ? '/affiliations' : '#'} onClick={ isAuthenticated ? null : () => { 
      history.push({
      pathname: '/login' })
    }}><NavDropdown.Item href={user && user.status !== "C" && user.status !== "A" ? '#affiliations' : "#"}><Nav style={{fontWeight: 'bold', color : location.pathname === '/affiliations' ? theme === "light" ? 'black' : 'white' : ''}}>{t('NavBar.Affiliations')}</Nav></NavDropdown.Item></Link>
    </OverlayTrigger>
        </NavDropdown>

      


 
        <OverlayTrigger placement="bottom" overlay={isAuthenticated ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('NavBar.Login_access')}</Tooltip>}>
         <Link to= {isAuthenticated ? '/contact' : '#'}><Nav.Link className="px-2 py-2" href="#contact" onClick={ isAuthenticated ? null : () => { 
    history.push({
    pathname: '/login' })
  }}><Nav style={{fontWeight: 'bold', color : location.pathname === '/contact' ? newTheme === "light" ? '#bc9f51' : '#edd99a' : ''}}>{t('NavBar.Contact')}</Nav></Nav.Link></Link>
         </OverlayTrigger>


   {/*</Nav>*/}
      <Nav role="button" className="px-2 py-2" style={{fontWeight: 'bold', color: newTheme === "light" ? '#daa520' : '#bc9f51', display: isAuthenticated? "" : "none"}} onClick={logout} href='#!' /*eventKey={2}*/>
      <i className='fas fa-sign-out-alt' />{' '}{t('NavBar.Logout')}
      </Nav>
    
<Nav role="button" className="px-2 py-2" style={{fontWeight: 'bold'}} href='#!' /*eventKey={2}*/>
  <div style={{display: isAuthenticated? "" : "none"}}>
<Toggle />
    </div>
</Nav>
    <NavDropdown className="px-2 py-2" alignRight title= { 
     <img
    src={t('Language.Lang') === "English" ? EnglandFlag : t('Language.Lang') === "Français" ? FranceFlag : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? ItalyFlag : EnglandFlag}
    style={{ width: '25px' }}
    alt='Language...'
  /> } id="collasible-nav-dropdown" bsPrefix='popover-body'>
      <NavDropdown.Item style={{fontWeight: 'bold', color: t('Language.Lang') === "English" ? theme === "light" ? 'black' : 'white' : ''}} href="#" onClick={()=>handleClick('en')}>{t('NavBar.English')}</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item style={{fontWeight: 'bold', color: t('Language.Lang') === "Français" ? theme === "light" ? 'black' : 'white' : ''}} href="#" onClick={()=>handleClick('fr')}>{t('NavBar.French')}</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item style={{fontWeight: 'bold', color: t('Language.Lang') === "Italiano" ? theme === "light" ? 'black' : 'white' : ''}} href="#" onClick={()=>handleClick('it')}>{t('NavBar.Italian')}</NavDropdown.Item>
    </NavDropdown>

    </Nav>
  </NavbarBootstrap.Collapse>
</NavbarBootstrap>

  );


/*

  <Link to='/dashboard'><ReactBootstrap.Navbar.Brand>Home</ReactBootstrap.Navbar.Brand></Link>
    <ul>
    <li>
      <Link to='/profiles'>Peoples</Link>
    </li>
    <li>
      <Link to='/posts'>Posts</Link>
    </li>
    <li>
      <Link to='/sponsorship-tree'>SponsorshipTree</Link>
    </li>
    <li>
      <Link to='/dashboard'>
        <i className='fas fa-user' />{' '}
        <span className='hide-sm'>Dashboard</span>
      </Link>
    </li>
    <li>
      <a onClick={logout} href='#!'>
        <i className='fas fa-sign-out-alt' />{' '}Logout
      </a>
    </li>
  </ul>



      <ul>
      <li>
        <Link to='/profiles'>Peoples</Link>
      </li>
      <li>
        <Link to='/register'>Register</Link>
      </li>
      <li>
        <Link to='/login'>Login</Link>
      </li>
    </ul>
*/


// SHOW MENU ON LOGIN
 /* const guestLinks = (

<NavbarBootstrap collapseOnSelect expand="lg" bg="dark" variant="dark">
<NavbarBootstrap.Toggle aria-controls="responsive-navbar-nav" />
<NavbarBootstrap.Collapse id="responsive-navbar-nav">
  <Nav className="mr-auto">
  <Link to='/login'><Nav.Link href="#login">{t('NavBar.Login')}</Nav.Link></Link>

  </Nav>

</NavbarBootstrap.Collapse>
</NavbarBootstrap>
  );*/
  
  return (
    <Fragment>
        
    
    <nav className={!isAuthenticated && width > 1000 && (location.pathname === "/" || location.pathname === "/login") ? "color-nav-transparent sticky-top" : "color-nav sticky-top"}>{/* navbar bg-dark */}


  

      {!loading && (
        <div>
        
        {isAuthenticated ? authLinks : <NavbarLogout/>}
        </div>
        
      )}

    </nav>

    

    </Fragment>
  );
  
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  chat: state.chat,
});

export default connect(
  mapStateToProps,
  { logout, loadUser }
)(Navbar);
