import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import {
  GET_PURCHASES,
  PURCHASE_ERROR,
  DELETE_PURCHASE,
  ADD_PURCHASE,
  GET_PURCHASE,
  GET_USERS_PURCHASE,
  PURCHASES_SUCCESS,
  UPDATE_PURCHASE,
  UPDATE_PURCHASE_ERROR,
  LOGOUT,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
} from './types';

// Get all purchases
export const getPurchases = (id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/purchases/get_all_purchases/', body, config);
    dispatch({
      type: GET_PURCHASES,
      payload: res.data.purchases
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: PURCHASE_ERROR,
  //    payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

// Delete purchase
export const deletePurchase = id => async dispatch => {
  try {
    await axios.delete(`/api/purchases/${id}`);

    dispatch({
      type: DELETE_PURCHASE,
      payload: id
    });

    dispatch(setAlert('server_18_purchase', 'success'));
  } catch (err) {
    dispatch({
      type: PURCHASE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add purchase
export const addPurchase = ({ presenter, title, description, initial_selling_price, negotiated_selling_price, seller,/*offer_expiry_date,*/ further_information, delivery_method, max_participants, city, postal_code, country, imgToSendFirst, imgToSendSecond, imgToSendThird, history }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };


  const body = new FormData();
  body.append("presenter", presenter);
  body.append("title", title);
  body.append("description", description);
  body.append("initial_selling_price", initial_selling_price);
  body.append("negotiated_selling_price", negotiated_selling_price);
  body.append("seller", seller);
  body.append("further_information", further_information);
  body.append("delivery_method", delivery_method);
  body.append("max_participants", max_participants);
  body.append("city", city);
  body.append("postal_code", postal_code);
  body.append("country", country);
  body.append("imgFirst", imgToSendFirst);
  body.append("imgSecond", imgToSendSecond);
  body.append("imgThird", imgToSendThird);

  try {
    const res = await axios.post('/api/purchases/add_purchase', body, config);

    dispatch({
      type: ADD_PURCHASE,
      payload: res.data
    });

    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_20_purchase', 'success'));
    history.push('/all-purchases');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PURCHASE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  }
};



  // Update purchase
  export const updatePurchase = (id, state, userId, isAdmin) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id, state });
      const body1 = JSON.stringify({ id : userId });
    
    try {
      const res = await axios.post('/api/purchases/update_purchase/', body, config);
  
      if (res) {
        var res2 = {data: {purchases: []}};
        if (isAdmin) {
          const res2 = await axios.post('/api/purchases/get_all_purchases/', body1, config);
          dispatch({
            type: PURCHASES_SUCCESS,
            payload: res2.data.purchases
          });
        }
        else {
            const res2 = await axios.post('/api/purchases/get_purchases_for_clients/', body1, config);
            dispatch({
                type: GET_USERS_PURCHASE,
                payload: res2.data.purchases
            });
        }
      }
      dispatch({
        type: UPDATE_PURCHASE,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: UPDATE_PURCHASE_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };


  // Publish purchases
  export const publishPurchases = (userId) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id: userId });
    
    try {
      const res = await axios.post('/api/purchases/publish_purchases/', config);
      if (res) {
          const res2 = await axios.post('/api/purchases/get_all_purchases/', body, config);
  
          dispatch({
            type: PURCHASES_SUCCESS,
            payload: res2.data.purchases
          });
      }

      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: PURCHASE_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };


// Get purchase
export const getPurchase = id => async dispatch => {
    dispatch({
        type: SHOW_LOADING_API
      });
  try {
    const res = await axios.get(`/api/purchases/${id}`);

    dispatch({
      type: GET_PURCHASE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PURCHASE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

// Get purchase for clients
export const getPurchaseForClients = id => async dispatch => {
    dispatch({
        type: SHOW_LOADING_API
      });

    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ id : id });
    try {
      const res = await axios.post('/api/purchases/get_purchases_for_clients/', body, config);
  
      dispatch({
        type: GET_USERS_PURCHASE,
        payload: res.data.purchases
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: PURCHASE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };


  // Participate purchase
  export const participatePurchase = (purchaseId, userId) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ purchaseId, userId });
      const body1 = JSON.stringify({ id : userId });
    
    try {
      const res = await axios.post('/api/purchases/participate_purchase/', body, config);
  
      if (res) {
        const res2 = await axios.post('/api/purchases/get_purchases_for_clients/', body1, config);
        dispatch({
            type: GET_USERS_PURCHASE,
            payload: res2.data.purchases
        });
      }
      dispatch({
        type: UPDATE_PURCHASE,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: UPDATE_PURCHASE_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };


  // Withdraw purchase
  export const withdrawPurchase = (purchaseId, userId) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ purchaseId, userId });
      const body1 = JSON.stringify({ id : userId });
    
    try {
      const res = await axios.post('/api/purchases/withdraw_purchase/', body, config);
  
      if (res) {
        const res2 = await axios.post('/api/purchases/get_purchases_for_clients/', body1, config);
        dispatch({
            type: GET_USERS_PURCHASE,
            payload: res2.data.purchases
        });
      }
      dispatch({
        type: UPDATE_PURCHASE,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: UPDATE_PURCHASE_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };
