import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { addUser } from '../../actions/sponsorship';
import SponsorshipActions from './SponsorshipActions';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';
//import { CountryDropdown } from 'react-country-region-selector';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { getSponsorships } from '../../actions/sponsorship';
import noImage from '../../img/passport.png';
import pdfIcon from '../../img/pdf.png';
import DateButton from '../layout/DateButton';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

import { useHistory } from 'react-router-dom';

 

const AddUser = ({ getSponsorships, setAlert, addUser, 
    auth: { user, loading }, sponsorship: { sponsorships, deleted_affiliation, full_matrix, old_user, add_user }}) => {

  /*    useEffect(() => {
        getSponsorships(user._id);
      }, [getSponsorships, user._id]);

      useEffect(() => {
        getPartners(user._id) 
      }, [getPartners, user._id]); */

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    email2: '',
    password: '',
    password2: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    address: '',
    additional_address: '',
    city: '',
    postal_code: '',
    oldUserId: user._id
  });

  let history = useHistory();

  const imgRefFront = useRef(null);
  const imgRefBack = useRef(null);

  useEffect(() => {
    getSponsorships(user._id, false, false, false);
  }, [getSponsorships, user._id, add_user]);

   /* useEffect(() => {
      getPartners(user._id) 
    }, [getPartners, user._id]); */

  

  const { username, email, email2, password, password2, first_name, last_name, phone_number, address, additional_address, city, postal_code, oldUserId } = formData;

  const [country, setCountry] = useState("");
  const [countryLabel, setCountryLabel] = useState();

  const [gender, setGender] = useState("");
  const [imgFront, setImgFront] = useState(noImage);
  const [imgBack, setImgBack] = useState(noImage);
  const [imgToSendFront, setImgToSendFront] = useState(false);
  const [imgToSendBack, setImgToSendBack] = useState(false);
  const [imgTemp, setImgTemp] = useState(false);
  const [attachmentType, setAttachmentType] = useState("");



  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");


  const thisDate = new Date()
  thisDate.setFullYear(thisDate.getFullYear() - 18)
  const [startDate, setStartDate] = useState(thisDate);
  const [startDateChanged, setStartDateChanged] = useState(false);

  const { t } = useTranslation();
  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }
  
const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}

{imgTemp === "pdf" ? (<p>{t('Sponsorship.Compress_pdf')} <a href={`https://shrinkpdf.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Sponsorship.Here')}</a></p>)
: (imgTemp === "jpeg" || imgTemp === "jpg") ? (<p>{t('Sponsorship.Compress_jpeg')} <a href={`https://compressjpeg.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Sponsorship.Here')}</a></p>)
: imgTemp === "png" ? (<p>{t('Sponsorship.Compress_png')} <a href={`https://compresspng.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Sponsorship.Here')}</a></p>)
: ""}
</ModalBody>
<ModalFooter as="footer">


    {modalFirstButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
null
  }
</ModalFooter>
</Modal>


 
  /*var count = 0;
  for(var i = 0; i < sponsorships.length; ++i){
      if(sponsorships[i].status.charAt(0) !== "M" && sponsorships[i].status !== "J") {
        count++;
      }
  }*/

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = async e => {
   /* var newUsername = username
    if (username.includes("*", 1)) {
      newUsername = username.replace(/\*//*g, '');
    }*/
    var date_of_birth = startDate
    if (!startDateChanged) {
      date_of_birth = ""
    }
    e.preventDefault();
    if (email !== email2) {
      setAlert('server_28_user', 'danger');
    }
    if (password !== password2) {
      setAlert('server_11_user', 'danger');
    }
   /* if (!imgToSend) {
      setAlert('server_37_user', 'danger');
    } */
    /*if (!signature) {
      setAlert('server_38_user', 'danger');
    }*/ else {
        addUser({ oldUserId, username, first_name, last_name, country, gender, date_of_birth, email, password, phone_number, address, additional_address, city, postal_code, attachmentType, imgToSendFront, imgToSendBack, history });
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  };

 /* function uploadFile ({ target: { files } }) {
    console.log(files[0])
    let data = new FormData();
    data.append('file', files[0])
  } */

  function selectCountry (val) {
    setCountry(JSON.parse(val).index);
    setCountryLabel(val);
  }

  const dropDownSelected = event => {
    if (JSON.parse(event).index) {
    selectCountry(event)
    }
  }

  /*if ((count + deleted_affiliation >= 3 ) || full_matrix || (full_matrix && old_user) || user.payed_status !== 3) {
    return <Redirect to="/affiliations" />;
  }*/




  function handleGenderChange  (changeEvent) {
    setGender(changeEvent.currentTarget.value);
}

function handleAttachmentChange  (changeEvent) {
  setAttachmentType(changeEvent.currentTarget.value);
  setImgFront(noImage);
  setImgToSendFront(noImage);
  setImgBack(noImage);
  setImgToSendBack(noImage);
}



function handleAttachmentImgFrontChange  (changeEvent) {
  if (changeEvent.currentTarget.files[0]) {
  if (/*!changeEvent.currentTarget.files[0] ||*/ changeEvent.currentTarget.files[0].size > 1000000 && (attachmentType === "P"/* || !attachmentType*/)) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Sponsorship.Warning'));
    setModalBody(t('Sponsorship.File_too_large_1_MB') + "\n");
    setModalFirstButton(t('Sponsorship.Ok'));
    setModalIsOpen(true);
    imgRefFront.current.value = null;
  } 
  else if (changeEvent.currentTarget.files[0].size > 500000 && (attachmentType === "I" || attachmentType === "R")) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Sponsorship.Warning'));
    setModalBody(t('Sponsorship.File_too_large_0_5_MB') + "\n");
    setModalFirstButton(t('Sponsorship.Ok'));
    setModalIsOpen(true);
    imgRefFront.current.value = null;
  }
  else if (attachmentType !== ""){
    if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
      setImgFront(pdfIcon);
    }
    else {
      setImgFront(URL.createObjectURL(changeEvent.currentTarget.files[0]));
    }
    setImgToSendFront(changeEvent.currentTarget.files[0]);
  } 
}
}

function handleAttachmentImgBackChange  (changeEvent) {
 /* if (!changeEvent.currentTarget.files[0] || (changeEvent.currentTarget.files[0].size > 1000000 && attachmentType === "P") || (changeEvent.currentTarget.files[0].size > 500000 && (attachmentType === "I" || attachmentType === "R"))) {
    setModalTitle(t('Sponsorship.Warning'));
    setModalBody(t('Sponsorship.File_too_large'));
    setModalFirstButton(t('Sponsorship.Ok'));
    setModalIsOpen(true);
    imgRefBack.current.value = null;
  }*/
  if (changeEvent.currentTarget.files[0]) {
  if (/*!changeEvent.currentTarget.files[0] ||*/ changeEvent.currentTarget.files[0].size > 1000000 && (attachmentType === "P"/* || !attachmentType*/)) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Sponsorship.Warning'));
    setModalBody(t('Sponsorship.File_too_large_1_MB') + "\n");
    setModalFirstButton(t('Sponsorship.Ok'));
    setModalIsOpen(true);
    imgRefFront.current.value = null;
  } 
  else if (changeEvent.currentTarget.files[0].size > 500000 && (attachmentType === "I" || attachmentType === "R")) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Sponsorship.Warning'));
    setModalBody(t('Sponsorship.File_too_large_0_5_MB') + "\n");
    setModalFirstButton(t('Sponsorship.Ok'));
    setModalIsOpen(true);
    imgRefFront.current.value = null;
  }
  else if (attachmentType !== "") {
  if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
    setImgBack(pdfIcon);
  }
  else {
    setImgBack(URL.createObjectURL(changeEvent.currentTarget.files[0]));
  }
  setImgToSendBack(changeEvent.currentTarget.files[0]);
}
  }
}

function range(size, startAt) {
  return [...Array(size).keys()].map(i => startAt - i);
}


const maxThisDate = new Date()
maxThisDate.setFullYear(maxThisDate.getFullYear() - 18)
const minThisDate = new Date()
minThisDate.setFullYear(minThisDate.getFullYear() - 130)
minThisDate.getMonth(minThisDate.getMonth())


const years = range(113, thisDate.getYear() + 1900);
const months = [
  t('Sponsorship.January'),
  t('Sponsorship.February'),
  t('Sponsorship.March'),
  t('Sponsorship.April'),
  t('Sponsorship.May'),
  t('Sponsorship.June'),
  t('Sponsorship.July'),
  t('Sponsorship.August'),
  t('Sponsorship.September'),
  t('Sponsorship.October'),
  t('Sponsorship.November'),
  t('Sponsorship.December')
];

/*const ExampleCustomInput = ({ value, onClick }) => 
{
  const datearray = value.split("/");

  if (t('Language.Lang') === "Français") {
    value = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
  }
  return(
      <button type="button" className="btn btn-info" onClick={onClick}>
  {value}
</button>);
};*/
  

    return loading && user === null ? (
        <Spinner />
      ) : (

    <Fragment>

<SponsorshipActions />
<div className="scrollme"> 
      <h1 className='large text-primary'>{t('Sponsorship.Membership_form')}</h1>
      <p className='lead'>
        <i className='fas fa-user' /> {t('Sponsorship.Create_affiliation')}
      </p>
      <form className='form' onSubmit={e => onSubmit(e)}>
      <p className="h1">I. {t('Sponsorship.Connexion_identifier')}</p>
        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Sponsorship.Username')}
            name='username'
        //    value={username.replace(/\*/g, '')}
            value={username.replace(/[^a-zA-Z ]/g, '').trim()}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='email'
            placeholder={t('Sponsorship.Email_address')}
            name='email'
            value={email}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => onChange(e)}
          />
          <small className='form-text'>
          </small>
          </div>
        <div className='form-group'>
            <input
              type='email'
              placeholder={t('Sponsorship.Confirm_Email_address')}
              name='email2'
              value={email2}
              onPaste={e=>{
                e.preventDefault();
                return false
              }}
              onChange={e => onChange(e)}
            />
            </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder={t('Sponsorship.Password')}
            name='password'
            value={password}
            onCopy={e=>{
              e.preventDefault();
              return false
            }}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder={t('Sponsorship.Confirm_password')}
            name='password2'
            value={password2}
            onCopy={e=>{
              e.preventDefault();
              return false
            }}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => onChange(e)}
          />
        </div>


        <p className="h1">II. {t('Sponsorship.Personal_informations')}</p>
        <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
        <label style={{marginRight:"20px", marginTop: "10px"}}>
    <h4>
    {t('Sponsorship.Gender')} :
          </h4>
      </label>
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="male"
         className="custom-control-input"
                      checked={gender === 'M'} 
                      onChange={handleGenderChange} />
                      <label className="custom-control-label" for="male">
          {t('Sponsorship.Male')}
      </label>
      </div>
      
      <div className="custom-control custom-radio custom-control-inline">
      <input type="radio" value="F" 
        id="female"
        className="custom-control-input"
                      checked={gender === 'F'} 
                      onChange={handleGenderChange} />
                      <label className="custom-control-label" for="female">
                        {t('Sponsorship.Female')}
                      </label>
      </div>
    </div>


    <div className='form-group'>
          <input
            type='text'
            placeholder={t('Sponsorship.First_name')}
            name='first_name'
            value={first_name.replace(/[^a-zA-Z ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Sponsorship.Last_name')}
            name='last_name'
            value={last_name.replace(/[^a-zA-Z ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

    <div className='form-group' style= {{
   display: "flex",
   alignItems: "center" }}>

          <h4 style={{marginRight:"20px"}}>
    {t('Register.Date_birth')} :
    </h4>
      <DatePicker
      formatWeekDay={nameOfDay => 
       (
        (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ?
        nameOfDay.substr(0,3) === "Sun" ?
        t('Register.Sunday').substr(0,3) :
        nameOfDay.substr(0,3) === "Mon" ?
        t('Register.Monday').substr(0,3) :
        nameOfDay.substr(0,3) === "Tue" ?
        t('Register.Tuesday').substr(0,3) :
        nameOfDay.substr(0,3) === "Wed" ?
        t('Register.Wednesday').substr(0,3) :
        nameOfDay.substr(0,3) === "Thu" ?
        t('Register.Thursday').substr(0,3) :
        nameOfDay.substr(0,3) === "Fri" ?
        t('Register.Friday').substr(0,3) :
        nameOfDay.substr(0,3) === "Sat" ?
        t('Register.Saturday').substr(0,3)
        : nameOfDay.substr(0,3) : nameOfDay.substr(0,3)
       ) 
       /* nameOfDay.substr(0,3) */}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select className="form-control"
            value={date.getYear() + 1900}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select className="form-control"
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={startDate}
      maxDate={maxThisDate}
      minDate={minThisDate}
      customInput={<DateButton />}
      onChange={date => {
        setStartDate(date);
        setStartDateChanged(true)
      }}
      
    />
    </div>
        
   
        <div className='form-group'>
          <input
            type='tel'
            placeholder={t('Sponsorship.Phone_number')}
            name='phone_number'
            value={phone_number.replace(/\D/,'')}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Sponsorship.Address')}
            name='address'
            value={address.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Sponsorship.Additional_address') + " - (" + t('Register.Optional') + ")"}
            name='additional_address'
            value={additional_address.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Sponsorship.City')}
            name='city'
            value={city.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Sponsorship.Postal_code')}
            name='postal_code'
            value={postal_code.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>




    <div className='form-group'>



<select className="form-control" name="action" value={countryLabel} onChange={e => dropDownSelected(e.target.value)}>
<option value="">{t('Sponsorship.Select_country')}</option>
             <option value= {'{"index": "BE"}'}>{t('Country.BE')}</option>
             <option value= {'{"index": "FR"}'}>{t('Country.FR')}</option>
             <option value= {'{"index": "IT"}'}>{t('Country.IT')}</option>
             <option value= {'{"index": "LU"}'}>{t('Country.LU')}</option>
             <option value= {'{"index": "CH"}'}>{t('Country.CH')}</option>
             <option value= {'{"index": "GB"}'}>{t('Country.GB')}</option>
             <option value= {'{"index": "GP"}'}>{t('Country.GP')}</option>
             <option value= {'{"index": "GF"}'}>{t('Country.GF')}</option>
             <option value= {'{"index": "MQ"}'}>{t('Country.MQ')}</option>
             <option value= {'{"index": "RE"}'}>{t('Country.RE')}</option>
           </select>
        </div>

        <p className="h1">III. {t('Sponsorship.Supporting_documents')}</p>
        <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
        <label style={{marginRight:"20px", marginTop: "10px"}}>
    <h4>
    {t('Sponsorship.Type')}:
          </h4>
      </label>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="I" 
         id="id_card"
         className="custom-control-input"
                      checked={attachmentType === 'I'} 
                      onChange={handleAttachmentChange} />
                      <label className="custom-control-label" for="id_card">
          {t('Sponsorship.Id_card')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="P" 
         id="passport"
         className="custom-control-input"
                      checked={attachmentType === 'P'} 
                      onChange={handleAttachmentChange} />
                      <label className="custom-control-label" for="passport">
          {t('Sponsorship.Passport')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="R" 
         id="resident_card"
         className="custom-control-input"
                      checked={attachmentType === 'R'} 
                      onChange={handleAttachmentChange} />
                      <label className="custom-control-label" for="resident_card">
          {t('Sponsorship.Resident_card')}
      </label>
      </div>

 
      <img src={imgFront} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: 'block',
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor={attachmentType !== "" ? "file_front" : ""} onClick={() => attachmentType === "" ? setAttachmentType("P") : null} className="btn btn-secondary">{ (attachmentType === "I" || attachmentType === "R") ? t('Sponsorship.Choose_image_front') : t('Sponsorship.Choose_image')}</label>
          <input
           ref={imgRefFront}
            type='file'
            id='file_front'
            name='file_front'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgFrontChange}
          />   

<p>
       {imgToSendFront.name}
      </p>
<img src={imgBack} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: (attachmentType === "I" || attachmentType === "R") ? "block" : "none",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor="file_back" style = {{ display: (attachmentType === "I" || attachmentType === "R") ? "" : "none"}} className="btn btn-secondary">{t('Sponsorship.Choose_image_back')}</label>
          <input
           ref={imgRefBack}
            type='file'
            id='file_back'
            name='file_back'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgBackChange}
          /> 
          <p>
          {imgToSendBack.name}
         </p>

    </div>
    

       {/* <p className="h1">IV. {t('Sponsorship.Contract_signature')}</p>
        <div className='form-group'>
        <label>
        <input style={{marginRight:"5px"}} type="checkbox" value="C" 
                      checked={signature === true} 
                      onChange={handleSignatureChange} />
          {t('Sponsorship.Contract_accept1')} <Link to='/policies-procedures' target="_blank">{t('Sponsorship.Policies_procedures')}</Link> {t('Sponsorship.Contract_accept2') + " " + t('Sponsorship.Contract_accept3')}<Link to='/policies-procedures' target="_blank"> {t('Sponsorship.Policies_procedures')}</Link> {t('Sponsorship.Contract_accept4')}
      </label>
    </div> */}
    <br/>
        <input type='submit' className='btn btn-block btn-primary' value={t('Sponsorship.Confirm')} />
      </form>

      {showModal}
      </div>
    </Fragment>
  );
};



      /*
        <CountryDropdown
          defaultOptionLabel= {t('Sponsorship.Select_country')}
          value={country}
          onChange={(val) => selectCountry(val)} />
           */

AddUser.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  sponsorship: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    sponsorship: state.sponsorship,
    getSponsorships: PropTypes.func.isRequired
});

export default connect(
  mapStateToProps,
  { getSponsorships, setAlert, addUser }
)(AddUser);

